<template>
  <div>
    <b-row style="margin-bottom: 10px;">
<!--       <b-col> 
        <h3>Ticket Detayı</h3>
      </b-col> -->
    </b-row>
    <b-row style="margin: 1px;">
      <h4>{{d_operation_title}}</h4>
    </b-row>
    <b-row style="margin: 1px;">
      <p v-html="d_operation_about"></p>
    </b-row>
    <b-card v-for="(comment, comment_ind) in d_commentList" :key="comment_ind" class="card">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="card-title">{{ comment.who_commented }}</h6>
          <p class="card-text text-muted">{{ comment.comment_datetime }}</p>
        </div>
      </template>
      <p v-html="comment.message"></p>
    </b-card>
    <div style="margin-top: 30px;" v-if="d_status == 'Active'">
      <b-row class="text-area">
        <b-form-textarea v-model="d_commentArea" rows="3" max-rows="3" placeholder="Yorum Girin"></b-form-textarea>
      </b-row>
<!--       <template v-for="(task, task_ind) in d_taskList">
        <div v-if="task && task.data && task.data.general" style="padding: 3px;">
          <div class="task-comment" @click="f_showDocuments(task, task_ind)">
            <span style="margin-right: 10px;">Doküman Ekle:</span>
            <img src="@/icon/70094.png" width="25px" style="border-radius: 3px; cursor: pointer;" /> {{ task.document_list ? task.document_list.length : '' }}
          </div>
        </div>
      </template> -->
        <div v-if="d_operation_data && d_operation_data.data && d_operation_data.data.general" style="padding: 3px;">
          <div class="task-comment" @click="f_showDocuments(d_operation_data, 0)">
            <span style="margin-right: 10px;">Doküman Ekle:</span>
            <img src="@/icon/70094.png" width="25px" style="border-radius: 3px; cursor: pointer;" /> {{ d_operation_data.document_list ? d_operation_data.document_list.length : '' }}
          </div>
        </div>
      <b-row style="margin-top: 30px;">
        <b-col cols="9" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_saveNewComment()">Yorum Ekle</button>
        </b-col>
      </b-row>
    </div>

    <modal v-if="d_taskDocuments.show" @close="d_taskDocuments.show = false" :width="'750'">
      <h3 slot="header">
        <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" />
        Dokümanlar
      </h3>
      <div slot="body">
        <b-form-file @input="f_addDocumentFastToTask(d_taskDocuments.task_ind)" style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;" v-model="d_tmpFile" :accept="f_createAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')">
          <img src="@/icon/1530793.png" style="width: 2em;">
        </b-form-file>
        <template v-if="d_showTaskDocumentField && d_taskDocuments.task.document_list && d_taskDocuments.task.document_list.length > 0" v-for="(doc, doc_ind) in d_taskDocuments.task.document_list">
          <div style="margin: 5px;">
            <b-row style="margin: 0px;">
              <b-col cols="6">
                <b-button style="margin-right: 5px;" size="sm" variant="danger" @click="f_deleteDocumentInTask(d_taskDocuments.task_ind, doc_ind)"> <i class="fa fa-trash"></i> sil </b-button> {{ doc_ind + 1 }} ) {{ doc.file_name }}
              </b-col>
              <b-col cols="6">
                <b-button style="margin-right: 5px;" size="sm" v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension.toLowerCase()) !== -1" variant="primary" @click="f_previewImage(d_taskDocuments.task, doc_ind, doc.file_name)">önizle</b-button>
                <b-button style="margin-right: 5px;" size="sm" variant="secondary" @click="f_downloadDocument(d_taskDocuments.task, doc_ind, doc.file_name)">indir</b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_taskDocuments.show = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import WdmService from '@/services/wdm';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import FileTransferService from '@/services/file_transfer';
import FileSaver from 'file-saver';
import OrganizationService from '@/services/organization';
import moment from 'moment';
export default {
  name: 'TicketDetails',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
  }),
  components: {
    vSelect,
    Modal
  },
  data () {
    return {
      d_loading: {
        'status': false,
      },
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_operation_data: "",
      d_commentArea: "",
      d_operation_title: "",
      d_operation_about: "",
      d_commentList: [],
      d_status: this.$route.query.status,
      user: {},
      d_taskCommentSeenList: {},
      d_selectedTaskForComment: {},
      selected_task_index: '',
      d_taskDocuments: {
        'show': false,
        'task': '',
        'task_ind': ''
      },
      d_showTaskDocumentField: true,
      d_allOrganizationUsers: {},
      d_taskList: [],
      d_tmpFile: '',
      document_show_data: { 'file': '', 'file_name': '' },
      show_image_show_modal: false,

    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getOperation();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {

    f_getOperation: function () {
      let query = 'organization_id=' + this.$route.query.organization_id;
      query += '&operation_id=' + this.$route.query.operation_id;
      OrganizationService.operation_get(query)
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_operation_data = resp.data.operation_data;
            this.d_taskList.push(resp.data.operation_data);
            // this.d_taskList = resp.data.operation_data;
            this.d_operation_title = this.d_operation_data.data.general.operation_name.val;
            if (this.d_operation_data.data.general.note){
              this.d_operation_about = this.d_operation_data.data.general.note.val;
            }
            else{
              this.d_operation_about = "";
            }
            // console.log("this.d_operation_data: ", this.d_operation_data);
            this.f_showComments();
            this.f_commentOperation();
          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });
    },

    f_commentOperation: function () {
      this.d_commentList = [];
      try {
        let operation_comment = this.d_operation_data.data.general.comment.list;
        for (let comment_ind in operation_comment){
          let who_commented = this.d_operation_data.data.general.comment.list[comment_ind].who_commented.val.label;
          let comment_datetime = this.d_operation_data.data.general.comment.list[comment_ind].comment_datetime.val;
          let comment_datetime_first = comment_datetime.split("T")[0]
          let comment_datetime_last = comment_datetime.split("T")[1]
          comment_datetime_last = comment_datetime_last.split(".")[0]
          comment_datetime = comment_datetime_first + " " + comment_datetime_last
          let message = this.d_operation_data.data.general.comment.list[comment_ind].message.val;
          let comment_item = {
            "who_commented": who_commented,
            "comment_datetime": comment_datetime,
            "message": message
          };
          this.d_commentList.push(comment_item);
        }
      } catch (err) {}
      console.log("this.d_commentList:", this.d_commentList);
    },

    f_saveNewComment: function () {
      let query = 'organization_id=' + this.$route.query.organization_id;
      query += '&operation_id=' + this.$route.query.operation_id;
      if (!this.d_commentArea) {
        alert('Lütfen yorum kısmını doldurun.');
      } else {
        let data = {
          'data': {
            'new_comment': this.d_commentArea,
          }
        };
        this.$store.commit('MutationLoading', { 'status': true, 'data': {"label": "İşleminiz gerçekleştiriliyor."} });
        OrganizationService.operation_comment_new(query, data)
          .then(resp => {
            this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '12000') {
              // console.log("operation_data: ", resp.data.operation_data);
              // this.f_goToThisTicket();
              this.f_getOperation();
              this.d_commentArea = "";
            } else {
              alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
            }
          });
      }
    },

    f_showDocuments: function (task, task_ind) {
      this.d_taskDocuments.task = task;
      this.d_taskDocuments.task_ind = task_ind;
      this.d_taskDocuments.show = true;
    },

    f_addDocumentFastToTask: function (task_ind) {
      let task_data = this.d_taskList[task_ind];
      let file_name = this.d_tmpFile.name;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      if (["jpg", "jpeg", "png", "docx", "doc", "pdf", "xls", "xlsx", "html", "zip"].indexOf(fileExtension.toLowerCase()) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 10485760) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12730'));
        return;
      }
      var reader = new FileReader();
      var vm = this;
      if (!task_data.document_list) {
        task_data.document_list = [];
      }
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': '',
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_name
      };
      task_data.document_list.push(doc_data);
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        // console.log(reader.result);
        task_data.document_list[task_data.document_list.length - 1].document = reader.result;
        vm.f_saveOperationFast(task_ind, task_data);
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "docx", "doc", "pdf", "xlsx", "xls", "zip"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_deleteDocumentInTask: function (task_ind, doc_ind) {
      let task_data = this.d_taskList[task_ind];
      task_data.document_list.splice(doc_ind, 1);
      // task_data.document_list[doc_ind]['record_status'] = 'deleted';
      this.f_saveOperationFast(task_ind, task_data);
    },
    f_previewImage: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        this.document_show_data.file_name = wdmr.document_list[doc_ind].file_name;
        this.document_show_data.file = wdmr.document_list[doc_ind].document;
        this.show_image_show_modal = true;
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              if (resp.data.result[key].file) {
                this.document_show_data.file_name = file_name;
                this.document_show_data.file = resp.data.result[key].file;
                this.show_image_show_modal = true;
              }
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_downloadDocument: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        FileSaver.saveAs(wdmr.document_list[doc_ind].document, file_name);
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              FileSaver.saveAs(resp.data.result[key].file, file_name);
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_saveOperationFast: function (task_ind, operation_data, send_mail = 'no') {
      let layer_type = 'operation';
      let parent_wdmr_id = operation_data.id_tree[0]['id'][operation_data.id_tree[0]['id'].length - 2].split('_')[1];
      let query = 'organization_id=' + parent_wdmr_id;
      query += '&operation_id=' + operation_data.id;
      // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Operasyon kayıt işlemi tamamlanıyor. Lütfen bekleyiniz.' } });
      let data = {
        'operation_data': operation_data,
        'send_mail': send_mail,
      };

      OrganizationService.operation_save(query, data)
        .then(resp => {
          if (resp.data.status_code === '12000') {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            // console.log(resp);
            this.d_taskList[task_ind] = operation_data;
            this.d_taskList[task_ind].cas = resp.cas;

            // let cell_style_with_border = this.f_calculateStatusCellStyle(task_ind, '');
            // let cell_style_without_border = this.f_calculateStatusCellStyle(task_ind, '', 'no');
            // this.d_taskCellStyleList[operation_data['id']] = { 'with_border': cell_style_with_border, 'without_border': cell_style_without_border };
            // this.d_taskCommentSeenList[operation_data['id']] = this.f_calculateCountOfSeenComments(operation_data);

            // this.f_showTrueFalseTaskDocumentField();
            // if (this.selected_task_index !== '' && this.d_workTimeEditMode) {
            //   this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
            //   this.d_workTimeEditMode = false;
            //   this.d_newWorkTime = {
            //     'start': '',
            //     'end': '',
            //     'work_time_note': ''
            //   };
            // }

            if (this.d_taskDocuments.show) {
              this.d_taskDocuments.task = operation_data;
            }
            this.$forceUpdate();
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);

          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Operasyon yorum işlemi hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
    },

    f_showComments: function () {
      this.d_newTaskComment = '';
      // this.selected_task_list_type = type;
      // this.selected_task_index = task_ind;
      // this.f_whoCanSeeThisTask();
      for (let task_ind in this.d_taskList) {
        this.d_selectedTaskForComment = this.d_taskList[task_ind];
        let task = this.d_selectedTaskForComment;
        console.log("task", task);
        let task_updated = false;
        let comment_users = [];
        let user_pic_keys = [];
        if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
          for (let c in task.data.general.comment.list) {
            let user_value = task.data.general.comment.list[c].who_commented.val.value;
            if (!this.d_allOrganizationUsers[user_value] || (this.d_allOrganizationUsers[user_value] && !this.d_allOrganizationUsers[user_value].pic)) {
              comment_users.push(user_value);
              user_pic_keys.push('user_' + user_value + '_picture');
            }
            let comment_seen = false;
            if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
              for (let w in task.data.general.comment.list[c].who_saw.list) {
                if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.id) {
                  comment_seen = true;
                }
              }
            }
            let dt = new Date();
            if (!comment_seen) {
              task_updated = true;
              if (!task.data.general.comment.list[c].who_saw) {
                task.data.general.comment.list[c].who_saw = { 'list': [] };
              }
              let prepare_seen_data = {
                'user_saw': {
                  'val': {
                    'value': this.user.id,
                    'label': this.user.first_name + ' ' + this.user.last_name
                  }
                },
                'seen_datetime': {
                  'val': dt.toISOString()
                }
              }
              task.data.general.comment.list[c].who_saw.list.push(prepare_seen_data);
            }
          }
        }
        if (task_updated) {
          this.f_saveOperationFast(task_ind, task);
        }
        this.d_showTaskCommentsModal = true;
        /*      if (user_pic_keys.length > 0) {
                GlobalService.get_multiple_cb_documents({ 'key_list': user_pic_keys, 'bucket': 'uploads' })
                  .then(resp => {
                    if (resp.data.status_code === '12000') {
                      for (let i in resp.data.result) {
                        if (!this.d_allOrganizationUsers[resp.data.result[i].username]) {
                          this.d_allOrganizationUsers[resp.data.result[i].username] = { 'pic': '' };
                        }
                        this.d_allOrganizationUsers[resp.data.result[i].username].pic = resp.data.result[i].picture;
                      }
                      this.d_showTaskCommentsModal = true;
                      this.addEventListenerToWriting();
                    } else {
                      console.log('error:  ' + resp.data.message);
                    }
                  });
              } else {
                this.d_showTaskCommentsModal = true;
                this.addEventListenerToWriting();
              }*/
      }


    },

    f_goToThisTicket: function () {
      this.$router.push({ path: '/activeticketlist' });
    },
  },
  watch: {}
};

</script>

<style>
.ticket_item: {
  height: 100%;
  cursor: pointer;
}
.line {
  width: 100%; /* Çizginin uzunluğun*/
  height: 1px; /* Çizginin kalınlığı*/
  background-color: #000; /* Çizgi rengini*/
}

/*.ticket_item:hover {
  height: 100%;
  background: #85FFBD;
}
*/

.card {
  border: 1px solid rgb(252, 0, 153, 1);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.card-header {
  background-color: rgba(250, 240, 245);
  padding: 2px;
}

.card-body {
  padding: 10px;
}

.text-area{
  border: 1px solid rgb(252, 0, 153, 1);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 750px;
  margin-left: 2px;
}

.task-comment {
  text-align: left;
  float: left;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
}

.task-comment:hover {
  background: #9ff5ff;
}



</style>


